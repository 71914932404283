<template>
  <div>
    <loading :is-loading="isLoading"/>
    <template v-if="content && content.length > 0">
      <div class="container min-hpx-580">
        <div class="row mt-4">
          <div class="col-12 col-sm-6">
            <p class="font-header">ឯកសារភ្ជាប់</p>
          </div>
          <div class="col-12 col-sm-6 text-sm-right">
            <button class="btn btn-secondary btn-sm" @click="$router.back()">
              <i class="fas fa-arrow-circle-left"></i> {{$t('btn.back')}}
            </button>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-xs-12">
            <div class="grid-container">
              <template v-for="(item, key) in content">
                <template v-if="item.type === 'occupancy_for_public'">
                  <div class="grid-item" :key="key">
                    <a :href="base_api + '/' +item.src" target="_blank" class="thumbnail">
                      <img :src="checkMimesType(item)" :alt="item.filename" :title="item.filename" class="grid__image">
                      <template v-if="!(mimesType.image.includes(item.mine_type))">
                        <div class="w-100 text-center text-dark">{{item.filename}}</div>
                      </template>
                    </a>
                  </div>
                </template>
              </template>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="page-error">
        <div class="page-content">
          <div class="page-error-image">
            <img src="/img/undraw_no_data_qbuo.svg"/>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="page-error-text">
                <h5>មិនមានឯកសារភ្ជាប់ទេ</h5>
                <p>Not found attach documents</p>
                <a href="/" class="btn btn-primary btn-outline-primary text-white">
                  <i class="fa fa-arrow-left"></i> Back to Home
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Loading from '../../components/Loading'
export default {
  name: 'OccupancyDocument',
  components: { Loading },
  data () {
    return {
      base_api: process.env.VUE_APP_API,
      content: [],
      isLoading: false,
      mimesType: {
        image: ['png', 'jpg', 'jpeg'],
        pdf: ['pdf'],
        word: ['doc', 'docx'],
        excel: ['xls', 'xlsx'],
        powerPoint: ['ppt', 'pptx']
      }
    }
  },
  methods: {
    checkMimesType (item) {
      if (this.mimesType.pdf.includes(item.mine_type)) {
        return '/img/img_file/pdf.png'
      } else if (this.mimesType.word.includes(item.mine_type)) {
        return '/img/img_file/docx.png'
      } else if (this.mimesType.excel.includes(item.mine_type)) {
        return '/img/img_file/xls.png'
      } else if (this.mimesType.powerPoint.includes(item.mine_type)) {
        return '/img/img_file/ppt.png'
      } else {
        return this.base_api + '/' + item.src
      }
    },
    getDataQr (qr_code) {
      this.isLoading = true
      this.$axios.post(this.base_api + '/api/backend/certificate-occupancy/get-certificate-occupancy-data', {
        qr_code: qr_code
      }).then((response) => {
        if (response.data.data.documents && response.data.data.documents.length > 0) {
          this.content = response.data.data.documents
        }
      }).finally(() => {
        this.isLoading = false
      })
    }
  },
  mounted () {
    if (!(this.$route.params.qr_code)) {
      this.$router.replace({ name: 'home' })
    }
    this.getDataQr(this.$route.params.qr_code)
  }
}
</script>

<style scoped>
  .min-hpx-580 {
    min-height: 750px;
  }

  .thumbnail {
    display: block;
    padding: 4px;
    margin-bottom: 20px;
    line-height: 1.42857143;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    -webkit-transition: border .2s ease-in-out;
    -o-transition: border .2s ease-in-out;
    transition: border .2s ease-in-out;
  }

  .grid__image {
    width: 100%;
  }

  .grid-container {
    display: grid;
    grid-template-columns: repeat(4, minmax(266px, 1fr));
    grid-gap: 1em;
  }

  /* hover styles */
  .grid-item {
    position: relative;
  }

  .page-error {
    width: 100%;
    height: 61vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--primary-font);
  }

  .page-content {
    display: block;
    text-align: center;
  }

  img {
    width: 40%;
  }

  @media screen and (max-width: 1200px) {
    .grid-container {
      grid-template-columns: repeat(4, minmax(221px, 1fr));
    }
  }

  @media screen and (max-width: 992px) {
    .grid-container {
      grid-template-columns: repeat(3, minmax(221px, 1fr));
    }
  }

  @media screen and (max-width: 767px) {
    .thumbnail {
      margin-bottom: 0;
    }

    .table > tbody > tr > td > p {
      font-size: 14px;
    }

    .grid-container {
      grid-template-columns: repeat(2, auto);
    }
  }

  @media screen and (max-width: 400px) {
    .grid-container {
      grid-template-columns: repeat(1, auto);
    }
  }
</style>
