<template>
  <div class="loading"
       :style="`background-color: ${bg};`"
       v-show="isLoading">
    <template v-if="image">
      <div :class="animateClass" :style="`${hasPadding ? 'padding: 16px;' : ''}`">
        <img :src="url" alt="" width="100%">
      </div>
    </template>
    <template v-else>
      <i :class="`fa fa-${icon} fa-pulse fa-${size}x fa-fw`"
         :style="`color: ${icon_color}`"></i>
    </template>
  </div>
</template>

<script>
export default {
  name: 'Loading',
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    bg: {
      type: String,
      default: 'white'
    },
    icon: {
      type: String,
      default: 'spinner'
    },
    size: {
      type: String,
      default: '3'
    },
    icon_color: {
      type: String,
      default: '#000000'
    },
    image: {
      type: Boolean,
      default: false
    },
    url: {
      type: String,
      default: '/img/asora-logo.svg'
    },
    animateClass: {
      type: String,
      default: 'pulse'
    },
    hasPadding: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style scoped>
  .loading {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    z-index: 99999 !important;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .loading i {
    position: absolute;
    opacity: 1;
  }

  .pulse-linear {
    position: absolute;
    top: 50%;
    left: 50%;
    background:var(--primary);
    border-radius: 50%;
    transform: translate(-50%, -50%);
    margin: 10px;
    height: 150px;
    width: 150px;
  }

  .pulse-linear:before,
  .pulse-linear:after {
    content: '';
    display: block;
    position: absolute;
    border: 1px solid var(--tirdary);
    left: -45px;
    top: -45px;
    right: -45px;
    bottom: -45px;
    border-radius: 50%;
    animation: pulse-linear 1.5s linear infinite;
    opacity: 0;
    backface-visibility: hidden;
  }

  .pulse-linear:after {
    animation-delay: .5s;
  }

  .pulse {
    background: white;
    border-radius: 50%;
    margin: 10px;
    height: 150px;
    width: 150px;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    transform: scale(1);
    animation: pulse 2s infinite;
  }

  @keyframes pulse-linear  {
    0% {
      transform: scale(0.5);
      opacity: 0;
    }

    50% {
      opacity: 1;
    }

    100% {
      transform: scale(1.2);
      opacity: 0;
    }
  }

  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(46, 204,113, 0.7);
    }

    50% {
      transform: scale(1);
      box-shadow: 0 0 0 30px rgba(46, 204,113, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }
</style>
